import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b40b3a76&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./App.vue?vue&type=style&index=1&id=b40b3a76&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b40b3a76",
  null
  
)

component.options.__file = "App.vue"
export default component.exports