import { render, staticRenderFns } from "./ExtPopup.vue?vue&type=template&id=c841d058&scoped=true&"
import script from "./ExtPopup.vue?vue&type=script&lang=js&"
export * from "./ExtPopup.vue?vue&type=script&lang=js&"
import style0 from "./ExtPopup.vue?vue&type=style&index=0&id=c841d058&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c841d058",
  null
  
)

component.options.__file = "ExtPopup.vue"
export default component.exports